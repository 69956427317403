import React from "react"
import { InlineWidget } from "react-calendly"

const Calendly = () => {
  return (
    <div className="calendly">
      <InlineWidget url="https://calendly.com/indie-ridge/free-consultation" />
    </div>
  )
}

export default Calendly
